import React, { useRef, useEffect } from 'react'
import { useMediaQuery } from '@uidotdev/usehooks'

export default function CvSlideshow({ cvs }) {
   // Filter out CVs that do not have a valid picture
   const cvsToShow = cvs.filter((cv) => cv.photo && cv.photo !== '')
   const sliderRef = useRef(null)

   const isSmallDevice = useMediaQuery('only screen and (max-width : 992px)')

   useEffect(() => {
      const interval = setInterval(() => {
         if (sliderRef.current) {
            const newScrollPosition = sliderRef.current.scrollLeft + 1
            const maxScrollLeft = sliderRef.current.scrollWidth / 2

            // Reset the scroll position to the start if it reaches the end point
            if (newScrollPosition >= maxScrollLeft) {
               sliderRef.current.scrollLeft = 0
            } else {
               sliderRef.current.scrollLeft = newScrollPosition
            }
         }
      }, 10)

      return () => clearInterval(interval)
   }, [])

   return (
      <div className="w-full">
         <div
            ref={sliderRef}
            className="pb-12 sm:pb-32 duration-500 flex relative animate-fade-up overflow-hidden"
            style={{
               whiteSpace: 'nowrap',
               width: '100%',
               play: 'running',
               direction: 'normal',
               duration: '35s',
               delay: '0s',
               iterationCount: 'infinite',
               minwidth: 100,
               transition: 'transform 0.5s linear',
               animation: 'scroll fade up .75s forwards',
            }}
         >
            {cvsToShow.map((cv, index) => (
               <a
                  key={`${cv.id}-${index}`}
                  href={`https://web3me.xyz/${cv.username || cv.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:opacity-70 transition-all duration-500 ease-in-out"
               >
                  <div
                     key={index}
                     className={'relative aspect-[1/1] mx-4 slide rounded-md'}
                     style={
                        isSmallDevice
                           ? {
                                display: 'inline-block',
                                width: '220px', // Large card width
                                height: '280px', // Large card height
                                marginRight: '20px', // Space between cards
                                backgroundImage: `url(${cv.photo})`,
                                backgroundSize: 'cover', // Added for better background image handling
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                textAlign: 'center',
                                alignItems: 'end',
                                fontSize: '20px', // Large font size
                             }
                           : {
                                display: 'inline-block',
                                width: '400px', // Large card width
                                height: '500px', // Large card height
                                marginRight: '20px', // Space between cards
                                backgroundImage: `url(${cv.photo})`,
                                backgroundSize: 'cover', // Added for better background image handling
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                textAlign: 'center',
                                alignItems: 'end',
                                fontSize: '45px', // Large font size
                             }
                     }
                  >
                     <p
                        className="text-white absolute bottom-4 left-4 right-4 block font-extrabold"
                        style={{ fontFamily: 'VT323' }}
                     >
                        {cv.name}
                     </p>
                  </div>
               </a>
            ))}
         </div>
      </div>
   )
}
