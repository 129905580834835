import { useEffect, useState } from 'react';
import Image from 'next/image';
import useSelectedWallet from '@/hooks/useSelectedWallet';

const GlowyBorderCards = ({ iconUrl, title, description, onClick, disabled, type }) => {
  const [hovered, setHovered] = useState(false);
  const [error, setError] = useState('');
  const [selectedWallet] = useSelectedWallet();

  useEffect(() => {
    const syncPointer = ({ x, y }) => {
      document.documentElement.style.setProperty('--x', x.toFixed(2));
      document.documentElement.style.setProperty(
        '--xp',
        (x / window.innerWidth).toFixed(2)
      );
      document.documentElement.style.setProperty('--y', y.toFixed(2));
      document.documentElement.style.setProperty(
        '--yp',
        (y / window.innerHeight).toFixed(2)
      );
    };
    document.body.addEventListener('pointermove', syncPointer);

    return () => {
      document.body.removeEventListener('pointermove', syncPointer);
    };
  }, []);

  const handleClick = () => {
    if (!selectedWallet) {
      setError('Please connect your wallet to proceed.');
      setTimeout(() => setError(''), 3000); // Clear error after 3 seconds
      return;
    }

    if (disabled) {
      if (type === 'use-company') {
        setError('This wallet is not connected to any profile.');
      } else {
        setError('Please connect your wallet to proceed.');
      }
      setTimeout(() => setError(''), 3000); // Clear error after 3 seconds
    } else {
      onClick();
    }
  };

  return (
    <>
      <div
        id="app"
        style={{
          '--body': 'rgb(0, 10, 15)',
          '--icon': 0.59,
          '--movement': 149,
          '--spread': 480.09865,
          '--border': 2,
          '--radius': 12,
          '--saturation': 100,
          '--lightness': 50,
          '--size': 150,
          '--backdrop': 'hsl(0 0% 60% / 0.15)',
          '--backup-border': 'hsl(0 0% 60% / 0.2)',
          '--cardblur': 4,
          '--bg-spot-opacity': 0.1,
          '--border-spot-opacity': 1,
          '--border-light-opacity': 1,
          '--base': 68.60629,
        }}
      >
        <main>
          <div className="wrapper" style={{ '--top': 402.86264, '--left': 924.630859, }}>
            <article
              className={`card ${hovered ? '' : ''} cursor-pointer`} // Added cursor-pointer class
              data-glow="true"
              style={{ '--base': 68.60629, '--spread': 480.09865, 'border-radius': '13px' }}
              onClick={handleClick}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <div data-glow="true"></div>
              <div className="card__content blur-background">
                <div className="card-content-wrapper">
                  {type === 'create-company' ? (
                    <>
                      <div className="company-logos" >
                        <Image src="https://res.cloudinary.com/dkjo3cxxr/image/upload/v1721566095/yuga_rgon8e.webp" alt="Company Logo 1" className="company-logo cl-first" width={100} height={100}  />
                        <Image src="https://res.cloudinary.com/dkjo3cxxr/image/upload/v1719472760/logo-with-bg_eqjp9r.png" alt="Company Logo 2" className="company-logo cl-second" width={100} height={100} />
                        <Image src="https://res.cloudinary.com/dkjo3cxxr/image/upload/v1721568101/192051183-bored-ape-yacht-club-nft-logo-white-monkey-skull-mascot-isolated-on-black-background-flat-vector_le5qnb.jpg" alt="Company Logo 3" className="company-logo cl-third" width={100} height={100} />
                      </div>
                    </>
                  ) : type === 'create-personal' ? (
                    <Image src="https://res.cloudinary.com/dkjo3cxxr/image/upload/v1723462911/5310-removebg_mhidev.png" alt="Personal Icon" width={379} height={400} />
                  ) : type === 'use-personal' ? (
                    <Image src="/web3me.gif" alt="Personal Icon" width={379} height={400} />
                  ) : type === 'use-company' ? (
                    <Image src="/scan.gif" alt="Company Icon" width={379} height={400} />
                  ) : (
                    <Image src={iconUrl} alt="Icon" width={379} height={400} />
                  )}
                </div>
                <div className="">
                  <h2 className="">{title}</h2>
                </div>
                <div className="card__footer">
                  <p>{description}</p>
                </div>
              </div>
            </article>
            {error && (
              <div className="error-box">
                <p>{error}</p>
              </div>
            )}
          </div>
        </main>
      </div>
      <style jsx>{`
        .card__footer {
          position: absolute;
          bottom: 0; /* Поднимаем текст выше */
          width: 100%;
          background: rgba(0, 0, 0, 0.75) !important;
          color: white;
          padding: 10px;
          text-align: center;
          border-bottom-left-radius: 13px;
          border-bottom-right-radius: 13px;
        }
        
        .blur-background {
          backdropFilter: blur(10px); /* Применяем эффект бюра к фону */
          background: rgba(0, 0, 0, 0.3); /* Полупрозрачный фон для лучшего эффекта */
          transition: backdrop-filter 0.3s ease, filter 0.3s ease; /* Плавный переход для эффекта блюра фона */
        }
        .blur .blur-image,
        .blur .blur-background {
          backdrop-filter: blur(10px); /* Применяем эффект блюра к фону */
        }
        .card:not(.blur) .blur-image,
        .card:not(.blur) .blur-background {
          filter: blur(0);
          backdropFilter: blur(0);
        }
        .error-box {
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          padding: 10px;
          backgroundColor: rgba(255, 0, 0, 0.8);
          color: white;
          borderRadius: 5px;
          textAlign: center;
          transition: opacity 0.3s ease, transform 0.3s ease;
          opacity: ${error ? 1 : 0};
          pointerEvents: ${error ? 'auto' : 'none'};
        }
      `}</style>
    </>
  );
};

export default GlowyBorderCards;