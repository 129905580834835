import React, { useState } from 'react'
import ButtonCustom from '@/components/Button'
import RenderCvsList from '@/components/RenderCvsList'

export default function CvsList({ cvs, showMyCvs }) {
   const itemsPerPage = 48
   const [currentPage, setCurrentPage] = useState(1)

   const startIndex = (currentPage - 1) * itemsPerPage
   const endIndex = startIndex + itemsPerPage

   const totalPages = Math.ceil(cvs.length / itemsPerPage)

   const handlePrevClick = () => {
      if (currentPage > 1) {
         setCurrentPage(currentPage - 1)
      }
   }

   const handleNextClick = () => {
      if (currentPage < totalPages) {
         setCurrentPage(currentPage + 1)
      }
   }

   const visibleCvs = cvs.slice(startIndex, endIndex)

   return (
      <div>
         <h2 className="sr-only">Web3me</h2>

         <div className="grid grid-cols-2 gap-5 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 lg:gap-6 w-full">
            <RenderCvsList cvs={visibleCvs} showMyCvs={showMyCvs} />
         </div>

         {cvs.length > 6 && (
            <div className="py-10 space-y-3">
               <div className="flex justify-center space-x-3">
                  {currentPage > 1 && (
                     <ButtonCustom
                        onClick={handlePrevClick}
                        color={'white'}
                        variant={'outline'}
                        size={'lg'}
                     >
                        Prev
                     </ButtonCustom>
                  )}
                  <ButtonCustom
                     onClick={handleNextClick}
                     disabled={currentPage === totalPages}
                     size={'lg'}
                     color={'cyan'}
                     variant={'outline'}
                  >
                     Next
                  </ButtonCustom>
               </div>
               <div>
                  <p className="text-center text-sm text-gray-300">
                     Showing <span className="font-medium">{startIndex + 1}</span> to{' '}
                     <span className="font-medium">{endIndex}</span> of{' '}
                     <span className="font-medium">{cvs.length}</span> results
                  </p>
               </div>
            </div>
         )}
      </div>
   )
}
