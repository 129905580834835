import React from 'react'
import ButtonCustom from '@/components/Button'
import Link from 'next/link'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'
import  AddRecommendationModel  from './pages/CreateCv/Experience/WorkExperience/AddRecommendationModel'

export default function RenderCvsList({ cvs, showMyCvs, data = {} }) {
   const [openRecommendation, setOpenRecommendation] = React.useState(null)
   const [copiedCvId, setCopiedCvId] = React.useState(null)

   const handleOpenRecommendation = (cvIndex) => {
      setOpenRecommendation(cvIndex)
   }

   const copyToClipboard = async (cvId, text) => {
      try {
         await navigator.clipboard.writeText(text)
         setCopiedCvId(cvId)
         setTimeout(() => setCopiedCvId(null), 2000) // Reset after 2 seconds
      } catch (err) {
         console.error('Failed to copy: ', err)
      }
   }

   return cvs.map((cv, index) => (
      <>
         {!showMyCvs ? (
            <div
               key={cv.id}
               className="group relative flex flex-col overflow-hidden rounded-sm border border-neutral-800"
            >
               <div className={twMerge('aspect-[1/1] bg-neutral-900 group-hover:opacity-75')}>
                  <Link
                     href={`https://web3me.xyz/${cv.username || cv.id}`}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     {cv.photo || cv.canvas ? (
                        <Image
                           src={cv.photo || cv.canvas}
                           alt={cv.name}
                           layout="fill"
                           objectFit="cover"
                           className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                           loading="lazy"
                        />
                     ) : null}
                  </Link>
               </div>
               <div className="flex flex-1 flex-col justify-between space-y-1 p-4">
                  <h3 className="text-sm font-medium text-white">{cv.name}</h3>
                  <p className={'text-sm text-gray-200 truncate pb-2'}>{cv.bio}</p>
                  <div className="relative">
                     <ButtonCustom
                        color={'cyan'}
                        variant={'outline'}
                        className={
                           'max-w-full overflow-hidden text-left text-ellipsis whitespace-nowrap'
                        }
                        onClick={() =>
                           copyToClipboard(cv.id, `https://web3me.xyz/${cv.username || cv.id}`)
                        }
                        title={`web3me.xyz/${cv.username || cv.id}`}
                     >
                        <span className="block text-white overflow-hidden text-ellipsis whitespace-nowrap">
                           web3me.xyz/{cv.username || cv.id}
                        </span>
                     </ButtonCustom>
                     {copiedCvId === cv.id && (
                        <div className="absolute right-0 bottom-full mb-2 bg-white p-1 rounded-md shadow-md text-sm">
                           Copied!
                        </div>
                     )}
                  </div>
               </div>
            </div>
         ) : (
            <>
               <div className="relative">
                  <div
                     key={cv.id}
                     className="group relative flex flex-col overflow-hidden rounded-sm border border-neutral-800"
                  >
                     <div className={twMerge('aspect-[1/1] bg-neutral-900 relative')}>
                        <Link
                           href={`https://web3me.xyz/${cv.username || cv.id}`}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           {cv.photo || cv.canvas ? (
                              <Image
                                 src={cv.photo || cv.canvas}
                                 alt={cv.name}
                                 layout="fill"
                                 objectFit="cover"
                                 className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                                 loading="lazy"
                              />
                           ) : null}
                        </Link>
                     </div>
                     <div className="flex flex-1 flex-col justify-between p-3 space-y-2">
                        <h3 className="text-sm font-medium text-white">{cv.name}</h3>
                        <p className={'text-sm text-gray-200 truncate pb-2'}>{cv.bio}</p>

                        <ButtonCustom
                           color={'cyan'}
                           variant={'outline'}
                           className={
                              'w-full overflow-hidden text-left text-ellipsis whitespace-nowrap'
                           }
                           onClick={() =>
                              copyToClipboard(cv.id, `https://web3me.xyz/${cv.username || cv.id}`)
                           }
                        >
                           <span className="block text-white overflow-hidden text-ellipsis whitespace-nowrap">
                              {copiedCvId === cv.id
                                 ? 'Copied!'
                                 : `web3me.xyz/${cv.username || cv.id}`}
                           </span>
                        </ButtonCustom>

                        <ButtonCustom
                           color={'white'}
                           variant={'outline'}
                           onClick={() => handleOpenRecommendation(index)}
                        >
                           Add Recommendation
                        </ButtonCustom>
                     </div>
                     {openRecommendation === index && (
                        <AddRecommendationModel
                           open={true}
                           experienceId={data.experience?.[openRecommendation]?.id} // Safely access experience
                           onClose={() => setOpenRecommendation(null)}
                        />
                     )}
                  </div>
                  <Link href={`/edit/${cv.id}`}>
                     <Image
                        src="/images/edit-icon.png"
                        alt="Edit"
                        width={40}
                        height={40}
                        className={
                           'w-10 h-10 rounded-full p-1 bg-[#00f7f0] border-[3px] border-black absolute -top-2 -right-2'
                        }
                     />
                  </Link>
               </div>
            </>
         )}
      </>
   ))
}