import React, { useEffect, useState } from 'react'
import createSupabaseClient from '@/lib/supabase/createSupabaseClient'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import styled from '@emotion/styled'
import { GoArrowRight } from 'react-icons/go'
import { GoArrowLeft } from 'react-icons/go'

const Container = styled('div')`
   .scroll-menu {
      &::-webkit-scrollbar {
         display: none;
      }
   }
`

export default function SkillsList({ onSkillSelect }) {
   const [skills, setSkills] = useState([])

   useEffect(() => {
      ;(async () => {
         const supabase = createSupabaseClient()
         const { data: skills } = await supabase.from('skills').select('*').limit(10)
         setSkills(skills.map((skill) => skill.name))
      })()
   }, [])

   return (
      <Container className={'pt-3 pb-5'}>
         <ScrollMenu
            scrollContainerClassName={'scroll-menu'}
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
         >
            {skills.map((skill) => (
               <div
                  key={skill}
                  className="py-2 px-3 mr-2 items-center border border-primary-500/50 hover:bg-primary-500/90 text-gray-200 curs transition-colors duration-200"
                  onClick={() => onSkillSelect(skill)}
               >
                  <p className={'text-xs sm:text-sm text-gray-200 truncate'}>{skill}</p>
               </div>
            ))}
         </ScrollMenu>
      </Container>
   )
}

function LeftArrow() {
   const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

   if (isFirstItemVisible) {
      return null
   }

   return (
      <button
         onClick={() => scrollPrev()}
         className="arrow-right arrow flex items-center justify-center w-7 h-7 bg-neutral-900 hover:bg-neutral-600 rounded-full text-white mr-2"
      >
         <GoArrowLeft />
      </button>
   )
}

function RightArrow() {
   const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

   if (isLastItemVisible) {
      return null
   }

   return (
      <button
         onClick={() => scrollNext()}
         className="arrow-left arrow flex items-center justify-center w-7 h-7 bg-neutral-900 hover:bg-neutral-600 rounded-full text-white ml-2"
      >
         <GoArrowRight />
      </button>
   )
}
