import { useState, useEffect } from 'react';
import { jsonToObject, strToJson } from '@/utils/helpers/json';

function useLocalStorage(key, initialValue) {
   // State to store our value
   const [storedValue, setStoredValue] = useState(() => {
      if (typeof window === 'undefined') {
         return initialValue;
      }
      try {
         // Get from local storage by key
         const item = window.localStorage.getItem(key);
         // Parse stored json or if none return initialValue
         return item ? jsonToObject(item) : initialValue;
      } catch (error) {
         // If error also return initialValue
         console.log(error);
         return initialValue;
      }
   });

   useEffect(() => {
      try {
         if (typeof window !== 'undefined') {
            // Save to local storage
            if (typeof storedValue === 'string' || typeof storedValue === 'number') {
               window.localStorage.setItem(key, storedValue);
            } else {
               window.localStorage.setItem(key, strToJson(storedValue));
            }
         }
      } catch (error) {
         // A more advanced implementation would handle the error case
         console.log(error);
      }
   }, [key, storedValue]);

   // Return a wrapped version of useState's setter function that ...
   // ... persists the new value to localStorage.
   const setValue = (value) => {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
   };

   return [storedValue, setValue];
}

export default useLocalStorage;