import styled from 'styled-components';

const ArrowButton = styled.button`
  top: -12rem;
  left: -6.5%;
  margin-left: 0px;
  width: 40px;
  height: 40px;
  border: 1px solid ${({ color }) => color || '#000'};
  border-radius: 50%;
  background-color: ${({ disabled }) => (disabled ? '#f0f0f0' : 'transparent')};
  color: ${({ color, disabled }) => (disabled ? '#ccc' : color || '#000')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms linear;


  

  &:hover {
    background-color: ${({ color, disabled }) => (disabled ? '#f0f0f0' : `rgba(${hexToRgb(color || '#000')}, 0.8)`)};
    box-shadow: ${({ disabled, color }) => (disabled ? 'none' : `0 0 10px 0 ${color || '#000'}`)};
    color: ${({ disabled }) => (disabled ? '#ccc' : '#fff')};
  }

  svg {
    width: 20px;
    height: 20px;
    fill: ${({ color, disabled }) => (disabled ? '#ccc' : color || '#000')};
  }
`;







// Helper function to convert hex color to rgb
const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

export default ArrowButton;