import CvsList from '@/components/CvsList'
import { useEffect, useState, useCallback } from 'react'
import useSelectedWallet from '@/hooks/useSelectedWallet'
import TextInput from '@/components/TextInput'
import { useRouter } from 'next/router'
import { useDebounce } from '@/hooks/useDebounce'
import CvSlideshow from '@/components/CvSlideshow'
import SkillsList from '@/components/SkillsList'
import createSupabaseClient from '@/lib/supabase/createSupabaseClient'
import ArrowButton from '@/components/ArrowButton'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Image from 'next/image'
import useLocalStorage from '@/hooks/useLocalStorage'
import NewComponent from '@/components/CreateProfileCards'

const supabase = createSupabaseClient()

const fetchUserProfile = async (walletAddress ,type) => {
  
  if (type === 'personal') {
    const { data, error } = await supabase
      .from('pp_cvs')
      .select('id')
      .eq('owner', walletAddress)
      .single()
   return data
  } else if (type === 'company') {
    const { data, error } = await supabase
      .from('cp_cvs')
      .select('id')
      .eq('creator_id', walletAddress)
      .single()
    return data
  }
}

export default function Home({ cvs }) {
   const router = useRouter()
   const [myCvs, setMyCvs] = useState(cvs)
   const [showMyCvs, setShowMyCvs] = useState(false)
   const [selectedWallet] = useSelectedWallet()
   const [search, setSearch] = useState('')
   const searchDebounced = useDebounce(search, 100)
   const [showSlideshow, setShowSlideshow] = useState(true)
   const [walletExists, setWalletExists] = useState(false)
   const [profileType, setProfileType] = useState('personal')
   const [showMenu, setShowMenu] = useState(false)
   const [data, setData] = useLocalStorage('data', {})
   const [isLoading, setIsLoading] = useState(false) // Add loading state


   const handleSearchFilter = useCallback(
      (search) => {
         const searchedCvs = cvs.filter((cv) => {
            return (
               cv.name.toLowerCase().includes(search) ||
               cv.username?.toLowerCase().includes(search) ||
               cv.bio?.toLowerCase().includes(search) ||
               cv.owner?.toLowerCase().includes(search) ||
               cv.job?.toLowerCase().includes(search) ||
               cv.skills?.some((skill) => skill.toLowerCase().includes(search))
            )
         })
         setMyCvs(searchedCvs)
      },
      [cvs]
   )

   useEffect(() => {
      if (router.query.search && router.query.search !== search) {
         const searchValue = router.query.search?.trim().toLowerCase()
         setSearch(searchValue)
         handleSearchFilter(searchValue)
      }
   }, [router.query, search, handleSearchFilter])

   useEffect(() => {
      if (showMyCvs) {
         router.push('/', undefined, { shallow: true })
         const myCvs = cvs.filter((cv) => cv.owner === selectedWallet)
         setMyCvs(myCvs)
      } else {
         if (searchDebounced.length > 0) {
            setShowSlideshow(false)
            handleSearchFilter(searchDebounced)
         } else {
            setShowSlideshow(true)
            setMyCvs(cvs)
         }
      }
   }, [searchDebounced, showMyCvs, handleSearchFilter, cvs, selectedWallet, router])

   useEffect(() => {
      const checkWalletExists = async () => {
      
         if (selectedWallet) {
            setIsLoading(true) // Set loading to true
            const { data: personalProfile } = await supabase
               .from('pp_cvs')
               .select('id')
               .eq('owner', selectedWallet)
               .single()
            if (personalProfile) {
               setWalletExists(true)
               setProfileType('personal')
               setIsLoading(false) // Set loading to false
               return
            }

            const { data: companyProfile } = await supabase
               .from('cp_cvs')
               .select('id')
               .eq('creator_id', selectedWallet)
               .single()
            if (companyProfile) {
               setWalletExists(true)
               setProfileType('company')
               setIsLoading(false) // Set loading to false
            } else {
               setWalletExists(false)
               setIsLoading(false) // Set loading to false
            }
         }
        // setIsLoading(false) // Set loading to false
      }
      checkWalletExists()
   }, [selectedWallet])

   const handleRedirect = () => {
      if (walletExists) {
         if (profileType === 'personal') {
            router.push('/personal')
         } else if (profileType === 'company') {
            router.push('/company')
         }  
      }
   }

   const handleSearchChange = (e) => {
      const value = e.target.value.trim().toLowerCase()
      setSearch(value)
      if (value.length === 0) {
         router.push('/', undefined, { shallow: true })
      } else {
         router.push(
            '/',
            {
               query: { search: value },
            },
            {
               shallow: true,
            }
         )
      }
   }

   const handleSkillSelect = (skill) => {
      setSearch(skill.toLowerCase())
   }

   const handleBack = () => {
      setShowMenu(false)
   }

   const handleSelect = async (type) => {
      setData({ ...data, type })
      const userProfile = await fetchUserProfile(selectedWallet,type)
      if (type === 'personal') {
         if (userProfile) {
            router.push('/personal')
         } else {
            router.push('/create/personal')
         }
      } else if (type === 'company') {
         if (userProfile) {
            router.push('/company')
         } else {
            router.push('/create/company')
         }
      }
   }

   if (isLoading) {
      return (
         <div className="flex flex-col items-center justify-center h-screen">
            
            <p className="text-[#00f3f7] text-3xl font-bold"> Web3Me is Loading...</p>
            <div>
               <Image src="/images/web3me.png" alt="Loading" width={500} height={500} />
            </div>
         </div>
      )
   }

   return (
      <>
         <div className={'flex flex-col items-center space-y-4 mt-10 sm:mt-20 text-center'}>
            <p className="text-lg font-semibold text-white ">Proof Anything On-Chain</p>
            {showMenu && (
               <div className="z-10">
                  <ArrowButton onClick={handleBack} color="#00f3f7">
                     <svg viewBox="0 0 24 24">
                        <path d="M15 19l-7-7 7-7" />
                     </svg>
                  </ArrowButton>
               </div>
            )}
            {!showMenu ? (
               <>
                  <div className="flex flex-col gap-4 sm:flex-row mb-12">
                     <NewComponent
                        onClick={() => setShowMenu(true)}
                        title=""
                        description="CREATE YOUR PROFILE"
                        disabled={!selectedWallet}
                        type="use-personal"
                     />
                     <NewComponent
                        onClick={handleRedirect}
                        title=""
                        description="EDIT YOUR PROFILE"
                        disabled={!walletExists}
                        type="use-company"
                     />
                  </div>
               </>
            ) : (
               <>
                  <div className="flex flex-col gap-4 sm:flex-row mb-12">
                     <NewComponent
                        onClick={() => handleSelect('personal')}
                        title=""
                        description="PERSONAL"
                        disabled={!selectedWallet}
                        type="create-personal"
                     />
                     <NewComponent
                        onClick={() => handleSelect('company')}
                        title=""
                        description="COMPANY"
                        disabled={!selectedWallet}
                        type="create-company"
                     />
                  </div>
               </>
            )}
            <div className="home-linkss text-white text-center">
               <p className="text-base font-semibold">
                  Buy a Forsaken to Unlock Lifetime Free Access
               </p>
               <div className="flex justify-center gap-3 mt-3">
                  <a
                     href="https://discord.gg/forsakennft"
                     target="_blank"
                     rel="noopener noreferrer"
                     className="rounded-sm bg-dark-blue text-white py-2 px-4 flex items-center gap-2"
                     style={{ backgroundColor: '#1e1e2e' }}
                  >
                     <Image
                        src="/logos/logo.png"
                        alt="Discord"
                        width={20}
                        height={20}
                        style={{ filter: 'invert(1)' }}
                     />
                     Discord
                  </a>
                  <a
                     href="https://magiceden.io/en/collections/base/0x73561f402cba2dbdb36fd3968948970a9a617575"
                     target="_blank"
                     rel="noopener noreferrer"
                     className="me-collection rounded-sm text-white py-2 px-3 flex items-center gap-2"
                  >
                     <Image src="/logos/magicEden.png" alt="Magic Eden" width={16} height={16} />
                     Magic Eden
                  </a>
               </div>
            </div>
         </div>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
         />
         <div className="top-right-logo">
            <Image src="/images/web3me.png" alt="Web3Me Logo" width={50} height={50} />
         </div>
      </>
   )
}
