export const jsonToObject = (str) => {
   try {
      return JSON.parse(str)
   } catch (e) {
      return str
   }
}

export const strToJson = (str) => {
   try {
      return JSON.stringify(str)
   } catch (e) {
      return str
   }
}
