import Layout from '@/components/layout'
import createSupabaseServer from '@/lib/supabase/createSupabaseServer'
import Home from '@/components/Home'

const HomePage = ({ cvs }) => {
   return (
      <Layout headTitle={'Home'} cvs={cvs}>
         <Home cvs={cvs} />
      </Layout>
   )
}

export async function getServerSideProps() {
   const supabase = createSupabaseServer()
   const { data, error } = await supabase
      .from('cvs')
      .select('id, name, username, bio, job, owner, created_at, owner, canvas, photo')
      .order('created_at', { ascending: false })

   if (error) {
      return {
         props: {
            cvs: [],
         },
      }
   }

   /**
    * Get the CV's skills
    */
   const { data: skills, error: skillsError } = await supabase.from('cvs_skills').select(
      `
     cv_id,
     skills (
         name
     )
 `
   )

   if (!skillsError && skills?.length > 0) {
      for (const cv of data) {
         const cvSkills = skills.filter((skill) => skill.cv_id === cv.id)
         cv.skills = cvSkills.map((skill) => skill.skills.name)
      }
   }

   return {
      props: {
         cvs: data,
      },
   }
}

export default HomePage
